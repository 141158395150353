<template>
  <v-card min-height="520" flat>
    <v-card-text class="d-flex align-center justify-center">
      <v-sheet
        min-height="520"
        class="d-flex flex-column align-center justify-center"
      >
        <h1 class="fw-900 my-2 fs-50 primary--text">Thank You!</h1>
        <h2 class="fw-500 my-4 fs-20 primary--text">
          Your {{ type }} payment was completed successfully
        </h2>

        <div class="col-md-6 d-flex align-center justify-space-between">
          <v-icon class="mr-5" color="primary" size="60">
            mdi-email-send-outline
          </v-icon>
          <span class="secondary--text">
            An email receipt including the details bout your payment has been
            sent to the email address provided. Please keep it for your records.
          </span>
        </div>
        <div
          v-if="type === 'orders'"
          class="col-md-6 d-flex align-center justify-space-between"
        >
          <span class="secondary--text">
            You can visit the My Order page at any time to check the status of
            your orders.
          </span>
          <v-icon color="primary" class="ml-5" size="60">mdi-store</v-icon>
        </div>
        <div class="col-md-6 d-flex align-center justify-center">
          <v-btn
            class="text-none px-10 mx-1"
            color="primary"
            x-large
            depressed
            v-if="type === 'orders'"
            @click="$router.push({ name: 'offers-my-orders' })"
          >
            Go to My Orders
          </v-btn>
          <v-btn
            class="text-none px-10 mx-1"
            color="primary"
            x-large
            depressed
            v-if="type === 'orders'"
            @click="$router.push({ name: 'offers-home' })"
          >
            Back to Offers
          </v-btn>
          <v-btn
            class="text-none px-10"
            color="primary"
            x-large
            depressed
            v-if="type === 'invoice'"
            @click="$router.push({ name: 'invoice' })"
          >
            Go to Invoices
          </v-btn>
          <v-btn
            class="text-none px-10"
            color="primary"
            x-large
            depressed
            v-if="type === 'twilio-number'"
            @click="$router.push({ name: 'smsmms-settings' })"
          >
            Go to My Numbers
          </v-btn>
        </div>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    type: 'invoice'
  }),
  watch: {
    '$route.params.type': {
      handler: function (val) {
        this.type = val ? val : 'invoice'
      },
      immediate: true
    }
  }
}
</script>

<style></style>
